import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";

const pageSize = 4;

const OffersListing = ({ module }) => {
  const [numOfItemsToDisplay, setNumOfItemsToDisplay] = useState(pageSize);
  const [offerPosts, setOfferPosts] = useState([]);

  useEffect(() => {
    if (module?.offerPost?.length > 0) {
      setOfferPosts(module?.offerPost?.slice(0, numOfItemsToDisplay));
    }
  }, [numOfItemsToDisplay]);

  const onLoadMore = () => {
    setNumOfItemsToDisplay(numOfItemsToDisplay + pageSize);
  }

  return (
    <section className={`img-grid lg:py-120 py-60${module.extraClass ? ` ${module.extraClass}` : ""}`}>
      <div className="container-fluid">
        <div className="flex flex-wrap lg:mx-minus-15 mx-0 gap-y-20">
          {
            offerPosts?.length > 0 && offerPosts.map((post) => (
              <div className="lg:w-6/12 w-full lg:px-15 px-0" key={post.id}>
                <div className="img-grid-bx">
                  {post?.featuredImage?.node && (
                    <GatsbyImage
                      image={getImage(post?.featuredImage?.node)}
                      alt={post?.featuredImage?.node.altText}
                    />
                  )}
                  <div className="img-grid-content pt-30 lg:pr-80">
                    {post?.title && <h4 className="">{post.title}</h4>}
                    {
                      post?.content && (
                        <div className="content">
                          {parse(post.content)}
                        </div>
                      )
                    }
                    <div className="btn-custom flex flex-wrap items-center space-x-8 mt-20">
                      <Link to={post?.uri || "#"} className="btn-link gold">Explore</Link>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
          {
            module?.offerPost?.length > 0 && module?.offerPost?.length > numOfItemsToDisplay && (
              <div className="btn-custom text-center flex justify-center items-center w-full" onClick={onLoadMore}>
                <button className="button btn-transparent">Load more</button>
              </div>
            )
          }
        </div>
      </div>
    </section>
  )
}

export default OffersListing;
export const OffersListingFragment = graphql`
  fragment OffersListingFragment on WpPage_Pagecontent_PageContent_OffersListing {
    id
    hideSection
    extraClass
    offerPost {
      ... on WpOffer {
        id
        content
        featuredImage {
          node {
            altText
            gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1024)
          }
        }
        uri
        title
      }
    }
  }
`;