import React from "react";
import { graphql } from "gatsby";
import Banner from "../components/Banner";
import FullSliderImageOrVideo from "../components/FullSliderImageOrVideo";
import SimpleContent from "../components/SimpleContent";
import SliderWithContent from "../components/SliderWithContent";
import AccommodationsGrid from "../components/AccommodationsGrid";
import HomeBlogListing from "../components/HomeBlogListing";
import StayListing from "../components/StayListing";
import FullImageOrVideo from "../components/FullImageOrVideo";
import ImageBox from "../components/ImageBox";
import TabbedDetails from "../components/TabbedDetails";
import JourneyListing from "../components/JourneyListing";
import SmallImageWithContent from "../components/SmallImageWithContent";
import OffersListing from "../components/OffersListing";
import AboutTeamGrid from "../components/AboutTeamGrid";
import ImageWithTabContent from "../components/ImageWithTabContent";
import Table from "../components/Table";
import GalleryTabs from "../components/GalleryTabs";
import ContactDetails from "../components/ContactDetails";
import NeedToKnowContent from "../components/NeedToKnowContent";

const Main = (props) => {
  const modules = props.modules.pageContent;
  const { location } = props;

  const components = {
    Banner,
    FullSliderImageOrVideo,
    SimpleContent,
    SliderWithContent,
    AccommodationsGrid,
    HomeBlogListing,
    StayListing,
    FullImageOrVideo,
    ImageBox,
    TabbedDetails,
    JourneyListing,
    SmallImageWithContent,
    OffersListing,
    AboutTeamGrid,
    ImageWithTabContent,
    Table,
    GalleryTabs,
    ContactDetails,
    NeedToKnowContent
  }

  return (
    <>
      {modules
        ? modules.filter((module) => !module?.hideSection || module?.hideSection === "no").map((module, i) => {
            const moduleName = module.__typename.replace(
              "WpPage_Pagecontent_PageContent_",
              ""
            );
            return (
              components[moduleName] &&
              React.createElement(components[moduleName], {
                key: i,
                module,
                location,
                i,
              })
            );
          })
        : null}
    </>
  )
};

export default Main;

export const MainFragment = graphql`
  fragment MainFragment on WpPage {
    pageContent {
      pageContent {
        __typename
        ...BannerFragment
        ...FullSliderImageOrVideoFragment
        ...SimpleContentFragment
        ...SliderWithContentFragment
        ...AccommodationsGridFragment
        ...HomeBlogListingFragment
        ...StayListingFragment
        ...FullImageOrVideoFragment
        ...ImageBoxFragment
        ...TabbedDetailsFragment
        ...JourneyListingFragment
        ...SmallImageWithContentFragment
        ...OffersListingFragment
        ...AboutTeamGridFragment
        ...MainPageImageWithTabContentFragment
        ...TableFragment
        ...GalleryTabsFragment
        ...ContactDetailsFragment
        ...NeedToKnowFragment
      }
    }
  }
`;
