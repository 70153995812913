import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link as Customlink, Element, Events, animateScroll as scroll } from 'react-scroll';

const TabbedDetails = ({ module }) => {
  useEffect(() => {
    scrollToTop()
  }, [])


  const handleScroll = () => {
    if (typeof window !== undefined) {
      if (window.pageYOffset > 200 && window.pageYOffset < 400) {
        document.querySelector('.tablinks-container > li:nth-child(1) > a').classList.add("active");
      }
    }
  };

  useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);


  const scrollToTop = () => {
    scroll.scrollToTop();
  }


  useEffect(() => {
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    }
  }, [])

  const [tabOption, setTabOption] = useState('Private Safaris');

  return (
    <section className={`vertical-tabs relative lg:mt-70 lg:pb-120 ${module.extraClass ? ` ${module.extraClass}` : ""}`}>
      <div className="container-fluid-xl mdscreen:hidden">
        <div className="flex flex-wrap">
          
          <div className="lg:w-5/12 w-full">
            <div className="sticky-block sticky top-[150px] pt-70 lgscreen:pt-30 lgscreen:relative lgscreen:top-0">
              <ul className='tabs w-full space-y-4 mt-20 lgscreen:space-y-0 lgscreen:pt-0 mb-30 flex flex-col lg:pt-0 pt-30 tablinks-container'>
                {module?.tabbedDetails?.length > 0 &&
                  module.tabbedDetails.map((tab, tabIndex) => (
                    <li 
                      key={tab.heading} 
                      id={`li-${tabIndex}`}
                      className={`tab-link cursor-pointer  ${(tabOption == tab.heading) ? 'tab-current' : ''}`}>
                        <Customlink
                          activeClass="active"
                          className={tab.heading}
                          to={tab.heading}
                          spy={true}
                          smooth={true}
                          id={`tabheading-${tabIndex}`}
                          offset={-200}
                        >
                          <span>{tab.heading}</span>
                        </Customlink>
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <div className="lg:w-7/12 w-full lg:pl-50">
            <div className="tabs-container lg:pb-0 pb-30">
              {module?.tabbedDetails?.length > 0 &&
                module.tabbedDetails.map((tab, tabIndex) => (
                  <Element name={tab.heading} className="element" key={tab.heading}>
                    <div id="overview" className="tab-content global-list" >
                      {
                        tab?.image && (
                          <GatsbyImage
                            image={getImage(tab.image)}
                            alt={tab?.image?.altText}
                          />
                        )
                      }
                      <div className="tabs-content pt-30">
                        <div className="title-black">
                          {tab.heading && <h4>{tab.heading}</h4>}
                        </div>
                        <div className="content">
                          {tab.description && <p>{tab.description}</p>}
                        </div>
                        <div className="btn-custom flex flex-wrap items-center space-x-8 mt-15">
                          <Link to={tab?.link?.url ? tab?.link?.url : "#"} className="btn-link gold">Explore</Link>
                        </div>
                      </div>
                    </div>
                  </Element>
                ))}
            </div>
          </div>

        </div>
      </div>

      <div class="hidden lgscreen:block mobile-vertical-tabs">
        {module?.tabbedDetails?.length > 0 &&
          module.tabbedDetails.map((tab, tabIndex) => (
            <div id="overview" className="tab-content global-list lgscreen:pt-20 lgscreen:px-20 lgscreen:mb-20" key={tab.heading}>
              {
                tab?.image && (
                  <GatsbyImage
                    image={getImage(tab.image)}
                    alt={tab?.image?.altText}
                  />
                )
              }
              <div className="tabs-content pt-30">
                <div className="title-black">
                  {tab.heading && <h4>{tab.heading}</h4>}
                </div>
                <div className="content">
                  {tab.description && <p>{tab.description}</p>}
                </div>
                <div className="btn-custom flex flex-wrap items-center space-x-8 mt-15">
                  <Link to={tab?.link?.url ? tab?.link?.url : "#"} className="btn-link gold">Explore</Link>
                </div>
              </div>
            </div>
          ))}
      </div>

    </section>
  )
}

export default TabbedDetails;
export const TabbedDetailsFragment = graphql`
  fragment TabbedDetailsFragment on WpPage_Pagecontent_PageContent_TabbedDetails {
    id
    hideSection
    extraClass
    tabbedDetails {
      heading
      description
      image {
        gatsbyImage(formats: WEBP, width: 1000)
        altText
      }
      link {
        url
      }
    }
  }
`;