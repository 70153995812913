import React, { useState } from "react";
import { graphql } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Modal from "react-modal";
import ReactPlayer from "react-player";
import { Autoplay } from "swiper";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const customStylesVideo = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#000000",
    border: "0",
    borderRadius: "0",
    padding: "0",
    width: "100%",
    height: "100%",
  },
};

const FullSliderImageOrVideo = ({ module }) => {
  const [modalIsOpenVideo, setIsOpenVideo] = React.useState(false);
  const [fullScreenVideoToPlay, setFullScreenVideoToPlay] = React.useState("");
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isLightBoxOpen, setLightBoxOpenStatus] = useState(false);

  const onlyImages = module?.slider?.filter((item) => item?.image?.mediaItemUrl);

  const openModalVideo = (fullVideoUrl) => {
    document.body.classList.add("video-modal-open");
    setIsOpenVideo(true);
    setFullScreenVideoToPlay(fullVideoUrl);
  };

  const closeModalVideo = () => {
    document.body.classList.remove("video-modal-open");
    setIsOpenVideo(false);
    setFullScreenVideoToPlay("");
  };

  const renderSlideItem = (slideItem, slideItemIndex) => {
    if (slideItem?.shortVideoUrl && slideItem?.shortVideoUrl !== "") {
      return (
        <SwiperSlide key={slideItemIndex.toString()}>
          <div className='video'>
            <video 
              width="750" 
              height="500" 
              preload="auto"
              playsInline="playsinline"
              autoPlay="autoplay"
              muted="muted"
              loop="loop" 
              poster={slideItem?.fallBackImage?.mediaItemUrl}
              data-src={slideItem?.shortVideoUrl}
            >
              <source src={slideItem?.shortVideoUrl} type="video/mp4" />
            </video>
            {
              (slideItem?.fullVideoUrl && slideItem?.fullVideoUrl !== "") && (
                <button onClick={() => openModalVideo(slideItem?.fullVideoUrl)} className='flex justify-center items-center m-auto'>
                  <div className='play-icon'>
                    <img src="/images/gallery-video.png" alt="Play" />
                  </div>
                </button>
              )
            }
          </div>
        </SwiperSlide>
      );
    }
    return (
      <SwiperSlide key={slideItemIndex.toString()}>
        <div 
          className="img fade-img cursor-pointer"
          onClick={() => {
            setPhotoIndex(onlyImages.findIndex((item) => item?.image?.id === slideItem?.image?.id));
            setLightBoxOpenStatus(true);
          }}
        >
          <GatsbyImage
            image={getImage(slideItem.image)}
            alt={slideItem?.image?.altText || "Slider Item Picture"}
          />
        </div>
      </SwiperSlide>
    );
  };

  return (
    <>
      <section
        className={`autoPlay-gallery bg-black-200 pt-50 lgscreen:pt-25${
          module.extraClass ? ` ${module.extraClass}` : ""
        }`}
      >
        <Swiper
          spaceBetween={30}
          loop={true}
          slidesPerView={"auto"}
          speed={900}
          grabCursor={true}
          // autoplay={{
          //   delay: 2000,
          //   disableOnInteraction: false,
          // }}
          pagination={{
            clickable: true,
          }}
          navigation={false}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {module?.slider?.length > 0 && module.slider.map(renderSlideItem)}
        </Swiper>
      </section>
      <Modal
        isOpen={modalIsOpenVideo}
        onRequestClose={closeModalVideo}
        style={customStylesVideo}
      >
        <div className="video-modal-popup h-full">
          <button onClick={closeModalVideo} className="pr-30 pt-30">
            <img
              src="/images/modal-close.png"
              className="m-auto max-w-20"
              alt=""
            />
          </button>
          <div className="modal-video h-full">
            <ReactPlayer
              className="modal-banner-video"
              loop={true}
              playing={true}
              controls={true}
              muted={true}
              url={fullScreenVideoToPlay}
            />
          </div>
        </div>
      </Modal>
      {isLightBoxOpen && (
        <Lightbox
          mainSrc={onlyImages?.[photoIndex]?.image.mediaItemUrl}
          nextSrc={onlyImages[(photoIndex + 1) % onlyImages.length]}
          onImageLoadError={() => { }}
          imageLoadErrorMessage={() => { }}
          prevSrc={
            onlyImages[
            (photoIndex + onlyImages.length - 1) % onlyImages.length
            ]
          }
          onCloseRequest={() => setLightBoxOpenStatus(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex) =>
                (photoIndex + onlyImages.length - 1) % onlyImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex(
              (photoIndex) => (photoIndex + 1) % onlyImages.length
            )
          }
        />
      )}
    </>
  );
};

export default FullSliderImageOrVideo;
export const FullSliderImageOrVideoFragment = graphql`
  fragment FullSliderImageOrVideoFragment on WpPage_Pagecontent_PageContent_FullSliderImageOrVideo {
    id
    hideSection
    extraClass
    slider {
      shortVideoUrl
      fullVideoUrl
      fallBackImage {
        altText
        mediaItemUrl
      }
      image {
        id
        altText
        gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 400)
        mediaItemUrl
      }
    }
  }
`;
