import React from "react";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ImageBox = ({ module }) => {
  return (
    <section
      className={`discover-our-stories bg-gray-200 lg:py-100 py-50${
        module.extraClass ? ` ${module.extraClass}` : ""
      }`}
    >
      <div className="container-fluid">
        <div className="section-title fade-ani text-center">
          {module?.heading && (
            <div className="title-black">
              <h3>{module.heading}</h3>
            </div>
          )}
          {module?.description && (
            <div className="content">{parse(module.description)}</div>
          )}
        </div>
        <div className="flex flex-wrap fade-img lg:mx-minus-15 lg:pt-50 pt-25 lgscreen:space-y-10">
          {module?.boxContent?.length > 0 &&
            module.boxContent.map((item, itemIndex) => (
              <div
                className="lg:w-6/12 w-full lg:px-15 px-0 fade-img"
                key={itemIndex.toString()}
              >
                <div className="discover-bx">
                  <GatsbyImage
                    image={getImage(item?.image)}
                    alt={item?.image?.altText || ""}
                  />
                  <div className="discover-bx-info lg:pt-30 pt-20 lg:pr-50 pr-0">
                    {item?.heading && (
                      <h6 className="text-black-200 text-24">{item.heading}</h6>
                    )}
                    {item?.shortDescription && (
                      <div className="content textsm text-14 text-[#4F5353] font-body font-300 mt-15">
                        {parse(item.shortDescription)}
                      </div>
                    )}
                    <div className="btn-custom flex flex-wrap items-center space-x-8 mt-20">
                      {item?.link && (
                        <Link to={item?.link?.url} className="btn-link gold">
                          {item.link?.title || "Explore"}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default ImageBox;
export const ImageBoxFragment = graphql`
  fragment ImageBoxFragment on WpPage_Pagecontent_PageContent_ImageBox {
    id
    boxContent {
      heading
      link {
        target
        title
        url
      }
      shortDescription
      image {
        altText
        gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
      }
    }
    description
    extraClass
    heading
  }
`;
export const ExperienceSingleImageBoxFragment = graphql`
  fragment ExperienceSingleImageBoxFragment on WpExperience_Experiences_ExperiencesContent_ImageBox {
    id
    hideSection
    boxContent {
      heading
      link {
        target
        title
        url
      }
      shortDescription
      image {
        altText
        gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
      }
    }
    description
    extraClass
    heading
  }
`;