import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";

const pageSize = 4;

const JourneyListing = ({ module }) => {
  const [numOfItemsToDisplay, setNumOfItemsToDisplay] = useState(pageSize);
  const [journeyPosts, setJourneyPosts] = useState([]);

  useEffect(() => {
    if (module?.journeyPost?.length > 0) {
      setJourneyPosts(module?.journeyPost?.slice(0, numOfItemsToDisplay));
    }
  }, [numOfItemsToDisplay]);

  const onLoadMore = () => {
    setNumOfItemsToDisplay(numOfItemsToDisplay + pageSize);
  }

  return (
    <section className={`img-grid lg:py-120 py-60${module.extraClass ? ` ${module.extraClass}` : ""}`}>
      <div className="container-fluid">
        <div className="flex flex-wrap fade-ani lg:mx-minus-15 mx-0 gap-y-10">
          {
            journeyPosts?.length > 0 && journeyPosts.map((post) => {
              const { image = null, facility = "", price = "" } = post.journey?.journeyFields;

              return (
                <div className="lg:w-6/12 w-full lg:px-15 px-0" key={post.id}>
                  <div className="img-grid-bx fade-img">
                    {image && (
                      <GatsbyImage 
                        image={getImage(image)}
                        alt={image.altText}
                      />
                    )}
                    <div className="img-grid-content pt-30 lg:pr-80">
                      <ul className="pb-10 flex flex-wrap">
                        {facility && <li>{facility}</li>}
                        {price && <li>{price}</li>}
                      </ul>
                      {post?.title && <h4 className="">{post.title}</h4>}
                      {
                        post?.content && (
                          <div className="content">
                            {parse(post.content)}
                          </div>
                        )
                      }
                      <div className="btn-custom flex flex-wrap items-center space-x-8 mt-20">
                        <Link to={post?.uri || "#"} className="btn-link gold">Explore</Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
          {
            module?.journeyPost?.length > 0 && module?.journeyPost?.length > numOfItemsToDisplay && (
              <div className="btn-custom text-center flex justify-center items-center w-full" onClick={onLoadMore}>
                <button className="button btn-transparent">Load more</button>
              </div>
            )
          }
        </div>
      </div>
    </section>
  )
}

export default JourneyListing;
export const JourneyListingFragment = graphql`
  fragment JourneyListingFragment on WpPage_Pagecontent_PageContent_JourneyListing {
    id
    hideSection
    extraClass
    journeyPost {
      ... on WpJourney {
        id
        content
        uri
        title
        journey {
          journeyFields {
            facility
            price
            image {
              altText
              gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1024)
            }
          }
        }
      }
    }
  }
`;