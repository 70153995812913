import { graphql, Link, navigate } from "gatsby";
import parse from "html-react-parser";
import React, { useState } from "react";

const ContactDetails = ({ module }) => {

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [form, setForm] = useState({
    input_1: "",
    input_2: "",
    input_3: "",
  });

  const [formError, setFormError] = useState({
    input_1: "",
    input_2: "",
    input_3: "",
  });

  return (
    <section className={`zigzag zigzag-contact fade-ani lg:py-120 py-20${module.extraClass ? ` ${module.extraClass}` : ""}`}>
      <div className="container-fluid">
        <div className="flex flex-wrap items-center">
          {module?.map &&
              <div className="lg:w-6/12 w-full mt-30 lg:mt-0 pr-30 lgscreen:pr-0 order-last lg:order-first">
                <div className="gatsby-image-wrapper">
                  {parse(module.map)}
                </div>
              </div>
            }
            <div className="lg:w-6/12 w-full">
            <div className="zigzag-content max-w-[443px] m-auto">
              <div>
                <h3 className="title-black">Get in touch with us</h3>
                <p className="content-block py-15 lg:pt-20">If you have any questions or concerns, please feel free to reach out to us at any time. You can either fill out the form below or <Link to="/guest-enquiry/" className="text-gold hover:opacity-50">make an enquiry</Link>.</p>
              </div>
              <div className="content order-first">
                <form>
                  <div className="w-full">
                    <div className="form-group flex flex-wrap lg:mt-20 mt-10">
                      <label
                        className="w-full text-black text-6"
                        htmlFor="Name"
                      >
                        Name*
                      </label>
                      <input
                        type="text"
                        placeholder="Type here..."
                        className="border-2 p-10 w-full text-12"
                        value={form.input_1}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            input_1: e.target.value,
                          });
                          setFormError({
                            ...formError,
                            input_1: "",
                          });
                        }}
                      />
                      {formError.input_1 && (
                        <div className="error">
                          <span className="text-red">{formError.input_1}</span>
                        </div>
                      )}
                    </div>
                    <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                      <label
                        className="w-full text-black text-6"
                        htmlFor="Email"
                      >
                        Email Address*
                      </label>
                      <input
                        type="email"
                        placeholder="Type here..."
                        className="border-2 p-10 w-full text-12"
                        value={form.input_2}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            input_2: e.target.value,
                          });
                          setFormError({
                            ...formError,
                            input_2: "",
                          });
                        }}
                      />
                      {formError.input_2 && (
                        <div className="error">
                          <span className="text-red">{formError.input_2}</span>
                        </div>
                      )}
                    </div>
                    <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                      <label
                        className="w-full text-black text-6"
                        htmlFor="Message"
                      >
                        Message*
                      </label>
                      <textarea
                        type="text"
                        placeholder="Type here..."
                        className="border-2 p-10 w-full h-24 text-12"
                        value={form.input_3}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            input_3: e.target.value,
                          });
                          setFormError({
                            ...formError,
                            input_3: "",
                          });
                        }}
                      />
                      {formError.input_3 && (
                        <div className="error">
                          <span className="text-red">{formError.input_3}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
                <div className="btn-custom flex flex-wrap items-center space-x-8 mt-30">
                <button
                    onClick={() => {
                      var validRegex =
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                      setFormError({
                        ...formError,
                        input_1:
                          form.input_1 === "" ? "Please enter your name." : "",
                        input_2:
                          form.input_2 === "" || !form.input_2.match(validRegex)
                            ? "Please enter valid email."
                            : "",
                        input_3:
                          form.input_3 === "" ? "Please enter last name." : "",
                      });
                      if (
                        form.input_1 !== "" &&
                        form.input_2 !== "" && form.input_2.match(validRegex) &&
                        form.input_3 !== ""
                      ) {
                        const requestOptions = {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify(form),
                        };
                        const url = `${process.env.GATSBY_SITE_URL}wp-json/gf/v2/forms/5/submissions`;
                        fetch(url, requestOptions).then((response) => {
                          setForm({
                            input_1: "",
                            input_2: "",
                            input_3: "",
                          });
                          if (response.status === 200) {
                            setFormSubmitted(true);
                            navigate("/thank-you/");
                          } else {
                            setFormError({
                              ...formError,
                              input_1: "Please try again.",
                            });
                          }
                        });
                      }
                    }}
                    className="button btn-gold cursor-pointer"
                  >
                    Send Message
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-30 md:mt-60 lg:mt-120 mx-auto">
          <div className="w-full text-center border-2 p-20 lg:p-60">
            <div className="max-w-[443px] m-auto">
              {module?.heading && <div className="title-black">
                <h3>{module.heading}</h3>
              </div>}
              <div className="content">

                {module?.description && parse(module.description)}

                <div className="content-block flex flex-col lg:pt-20">
                  {module?.emailTelephone?.length > 0 &&
                    module?.emailTelephone?.map((item, itemIndex) => (
                      <span key={itemIndex}>{parse(item.heading)}</span>
                    ))
                  }
                </div>
                {module?.address && parse(module.address)}
                <ul className="sicon flex pt-30 justify-center">
                  {module?.socialMedia?.length > 0 &&
                    module?.socialMedia?.map((item, itemIndex) => (
                      <li>
                        <Link to={item.link || "#"} target="_blank"><img src={item.icon.mediaItemUrl} alt="Social Image" /></Link>
                      </li>
                    ))
                  }
                </ul>
                {module?.link?.title &&
                  <div className="btn-custom lg:mt-30 mt-15">
                    <Link to={module?.link?.url || "#"} className="btn-link gold">{module?.link?.title}</Link>
                  </div>
                }
              </div>
            </div> 
          </div>
      </div>
    </section>
  )
}

export default ContactDetails;
export const ContactDetailsFragment = graphql`
  fragment ContactDetailsFragment on WpPage_Pagecontent_PageContent_ContactDetails {
    id
    hideSection
    heading
    map
    description
    map
    emailTelephone {
      heading
    }
    socialMedia {
      link
      icon {
        altText
        mediaItemUrl
      }
    }
    address
    link {
      target
      title
      url
    }
    extraClass
  }
`;