import React from "react";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const FullImageOrVideo = ({ module }) => {
  return (
    <section className={`full-img-content fade-img relative lg:mt-70 mt-35${module.extraClass ? ` ${module.extraClass}` : ""}`}>
      {
        module?.sectionStyle === "style2" && module?.videoUrl !== "" ? (
          <div className="video relative">
            <video
               width="750" 
               height="500" 
               preload="auto"
               playsInline="playsinline"
               autoPlay="autoplay"
               muted="muted"
               loop="loop" 
               poster={module?.fallbackImage?.mediaItemUrl}
               data-src={module?.videoUrl}
            >
              <source src={module?.videoUrl} type="video/mp4"/>
            </video>
        </div>
        ) : module?.image && (
          <GatsbyImage 
            image={getImage(module.image)}
            alt={module?.image?.altText}
          />
        )
      }
      
      <div className="img-content fade-ani px-15 absolute top-50-per translate-y-minus_50 w-full text-center">
          {module.preHeading && (
            <span className="font-heading text-white italic text-16">{module.preHeading}</span>
          )}
          {
            module?.heading && (
              <h2 className="text-40 lgscreen:text-34 leading-40 leading-53 text-white font-heading uppercase">{module.heading}</h2>
            )
          }
          {
            module?.description && (
              <div className="content white max-w-[414px] m-auto">
                {parse(module.description)}
              </div>
            )
          }
          {
            module?.button && (
              module?.button.target === "" ? (
                <div className="btn-custom flex flex-wrap items-center justify-center space-x-8 mt-30">
                  <Link to={module?.button.url} className="button btn-gold">{module?.button?.title}</Link>
                </div>
              ) : (
                <div className="btn-custom flex flex-wrap items-center justify-center space-x-8 mt-30">
                  <Link to={module?.button.url} target="_blank" className="button btn-gold">{module?.button?.title}</Link>
                </div>
              )
            )
          }
      </div>
    </section>
  )
}

export default FullImageOrVideo;
export const FullImageOrVideoFragment = graphql`
  fragment FullImageOrVideoFragment on WpPage_Pagecontent_PageContent_FullImageOrVideo {
    id
    hideSection
    button {
      target
      title
      url
    }
    buttonStyle
    description
    extraClass
    heading
    preHeading
    sectionStyle
    videoUrl
    fallbackImage {
      altText
      mediaItemUrl
    }
    image {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1920)
    }
  }
`;