import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";


const AboutTeamGrid = ({ module }) => {  
  const [displayContent, setDisplayContent] = useState({})

  const showContent = (index) => {
    setDisplayContent({ ...displayContent, [index]: !displayContent[index] });    
  }

  return (
    <section className={`img-grid-small fade-ani bg-gray-200 lg:py-120 py-60${module.extraClass ? ` ${module.extraClass}` : ""}`}>
      <div className="container-fluid">
        <div className="flex flex-wrap lg:mx-minus-15 mx-0 lgscreen:space-y-10">
          {module?.team?.length > 0 &&
            module.team.map((item, itemIndex) => (
              <div className="lg:w-4/12 w-full lg:px-15 px-0">
                <div className="img-grid-small-bx">
                  {/* <div className="img gatsby-image-wrapper"> */}
                  <GatsbyImage
                    image={getImage(item.image)}
                    alt={item?.image?.altText || "Item Picture"}
                  />
                  {/* </div> */}
                  <div className="lg:pt-30 pt-15">
                    {item?.heading &&
                      <div className="title-black">
                        <h6>{item.heading}</h6>
                      </div>
                    }
                    {item?.subHeading &&
                      <span className="text-black-500 text-14 mt-10 inline-block">{item?.subHeading}</span>
                    }
                    {!!displayContent[itemIndex] && item?.description && <div className="content">
                        {parse(item?.description)}                     
                    </div>}
                    {!displayContent[itemIndex] &&
                      <div className="btn-custom mt-15">
                        <button className="btn-link gold" onClick={() => showContent(itemIndex)}>Read More</button>
                      </div>
                    }
                    {!!displayContent[itemIndex] &&
                      <div className="btn-custom mt-15 content-expanded">
                        <button className="btn-link gold" onClick={() => showContent(itemIndex)}>See Less</button>
                      </div>
                    }
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default AboutTeamGrid;
export const AboutTeamGridFragment = graphql`
  fragment AboutTeamGridFragment on WpPage_Pagecontent_PageContent_AboutTeamGrid {
    id
    hideSection
    team {
      image {
        altText
        gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
      }
      heading
      subHeading
      link {
        target
        title
        url
      }
      description
    }
    extraClass
  }
`;