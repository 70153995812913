import React from "react";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser";
import Modal from "react-modal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectFade, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Table = ({ module }) => {

  const [modalIsOpenBox, setIsOpenBox] = React.useState(false);    

  function openModalBox() {
    document.body.classList.add("video-modal-open");
    setIsOpenBox(true);
  }
  function closeModalBox() {
    document.body.classList.remove("video-modal-open");
    setIsOpenBox(false);
  }

  const customStylesBox = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#ffffff",
      border: "0",
      borderRadius: "0",
      padding: "0",
      width: "100%",
      height: "100%",
    },
  };

  return (
    <>
      <section className={`rates${module.extraClass ? ` ${module.extraClass}` : ""}`}>
        <div className=" fade-ani  container-fluid">
          <div className="rates-block">
            <div className="top-info w-[695px] mdscreen:w-full mdscreen:px-60 m-auto text-center">
              {
                module?.heading && (
                  <div className="title-black">
                    <h3>{module?.heading}</h3>
                  </div>
                )
              }
              {
                module?.description && (
                  <div className="content">
                    {parse(module.description)}
                  </div>
                )
              }
              {
                module?.link && (
                  <div className="btn-custom flex flex-wrap justify-center items-center space-x-8 mt-20">
                    <button className='btn-link gold' onClick={() => { openModalBox(); }}>
                      {module?.link?.title}
                    </button>
                  </div>
                )
              }
            </div>
            <div className="rates-table lg:mt-50 mt-25">
              <div className="md:w-10/12 w-full m-auto lgscreen:w-[90%] mdscreen:w-full mdscreen:px-20 mdscreen:overflow-auto">
                <table className="table-auto w-full">
                  {
                    module?.data?.tableHead?.length > 0 && (
                      <thead>
                        <tr>
                          {
                            module?.data?.tableHead.map((cell, cellIndex) => (
                              <th key={cellIndex.toString()}>
                                {cell?.heading && <h4>{cell.heading}</h4>}
                                {cell?.caption && <span>{cell.caption}</span>}
                              </th>
                            ))
                          }
                        </tr>
                      </thead>
                    )
                  }
                  <tbody>
                    {
                      module?.data?.tableBody?.length > 0 && module?.data?.tableBody.map((row, rowIndex) => (
                        <tr key={rowIndex.toString()}>
                          {
                            row?.columns?.length > 0 && row.columns.map((cell, cellIndex) => (
                              <td key={cellIndex.toString()}>
                                {cell?.heading && <h4>{cell.heading}</h4>}
                                {cell?.caption && <span>{cell.caption}</span>}
                              </td>
                            ))
                          }
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Modal
            isOpen={modalIsOpenBox}
            onRequestClose={closeModalBox}
            style={customStylesBox}
          >
            <div className="zigzag-modal-popup h-full">
              <button onClick={closeModalBox} className="pr-30 pt-30 absolute right-0 top-0 z-9 mdscreen:w-50 mdscreen:h-50 mdscreen:bg-white mdscreen:rounded-999 mdscreen:right-20 mdscreen:top-20 mdscreen:p-0">
                <img src="/images/modal-close.png" className='m-auto max-w-20' alt="" />
              </button>
              <div className="modal-content h-full">
                <div className="flex flex-wrap">
                  <div className="md:w-6/12 w-full">
                    <div className='zigzag-slider fade-img'>
                      <Swiper
                        spaceBetween={0}
                        loop={true}
                        speed={1000}
                        slidesPerView={1}
                        effect={"fade"}
                        navigation={true}
                        modules={[EffectFade, Navigation]}
                        className=""
                      >
                        {
                          module?.modal?.modalImages?.length > 0 && module.modal.modalImages.map((image, index) => (
                            <SwiperSlide key={index.toString()}>
                              <div className="img">
                                <GatsbyImage
                                  image={getImage(image)}
                                  alt={image?.altText || "Slider Image"}
                                />
                              </div>
                            </SwiperSlide>
                          ))
                        }
                      </Swiper>
                    </div>
                  </div>
                  <div className="md:w-6/12 w-full">
                    <div className="zigzag-modal-content lg:px-115 desktop2:px-80 lgscreen:px-40 h-full flex flex-col justify-center mdscreen:py-30">
                      {module?.modal?.modalHeading &&
                        <div className="title-black">
                          <h3>{module?.modal?.modalHeading}</h3>
                        </div>
                      }
                      {module?.modal?.modalDescription &&
                        <div className="content global-list">
                          {parse(module?.modal?.modalDescription)}
                        </div>
                      }

                      {module?.modal?.modalButton?.title && <div className="btn-custom flex flex-wrap items-center space-x-8 mt-30">
                        <Link to={module?.modal?.modalButton?.url || "#"} className="button btn-gold">{module.modal.modalButton.title}</Link>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </section>
    </>
  )
}

export default Table;
export const TableFragment = graphql`
  fragment TableFragment on WpPage_Pagecontent_PageContent_Table {
    id
    hideSection
    extraClass
    heading
    description
    link {
      target
      title
      url
    }
    data {
      tableBody {
        columns {
          caption
          heading
        }
      }
      tableHead {
        caption
        heading
      }
    }
    modal {
      modalHeading
      modalDescription
      modalButton {
        target
        title
        url
      }
      modalImages {
        altText
        gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
      }
    }
  }
`;