import React from "react";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectFade, Navigation } from "swiper";

const StayListing = ({ module }) => {
  const accommodation = module.stayList;
  const sliderImages =
    accommodation.accommodations?.accommodationsFields?.imageSlider || [];
  const facility =
    accommodation.accommodations?.accommodationsFields?.facility || "";
  const bookingButton =
    accommodation.accommodations?.accommodationsFields?.bookingButton || "";
  const price = accommodation.accommodations?.accommodationsFields?.price || "";
  const content = accommodation.content;

  return (
    <section
      className={`zigzag lg:py-60 py-30 lg:mt-60 mt-30${
        module.extraClass ? ` ${module.extraClass}` : ""
      }`}
    >
      <div className="container-fluid">
        <div className="flex flex-wrap items-center">
          <div className={`lg:w-6/12 fade-img w-full ${module.imageSliderPostion === "left" ? 'pr-30 lgscreen:pr-0 lg:order-1' : 'pl-30 lgscreen:pl-0 lg:order-2'}`}>
            <div className="zigzag-slider fade-img">
              <Swiper
                spaceBetween={0}
                loop={true}
                speed={1000}
                slidesPerView={1}
                effect={"fade"}
                navigation={true}
                modules={[EffectFade, Navigation]}
                className=""
              >
                {sliderImages?.length > 0 &&
                  sliderImages.map((slideImage, slideImageIndex) => (
                    <SwiperSlide key={slideImageIndex.toString()}>
                      <GatsbyImage
                        image={getImage(slideImage?.image)}
                        alt={slideImage?.image?.altText}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
          <div
            className={`lg:w-6/12 fade-ani w-full ${
              module.imageSliderPostion === "left" ? "order-2" : "order-1"
            }`}
          >
            <div className="zigzag-content max-w-[443px] m-auto	">
              <div className="title-black">
                <h3>{accommodation?.title}</h3>
              </div>
              <div className="pre-content mt-15">
                <ul className="flex flex-wrap space-x-7">
                  <li>{facility}</li>
                  <li>{price}</li>
                </ul>
              </div>
              <div className="content">{content && parse(content)}</div>
              <div className="btn-custom flex flex-wrap items-center space-x-8 mt-30">
                <Link
                  to={accommodation?.uri}
                  className="button btn-transparent"
                >
                  Explore
                </Link>
                <Link to={bookingButton?.url} target="_blank" className="btn-link gold">
                  {bookingButton.title}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StayListing;
export const StayListingFragment = graphql`
  fragment StayListingFragment on WpPage_Pagecontent_PageContent_StayListing {
    id
    hideSection
    extraClass
    imageSliderPostion
    stayList {
      ... on WpAccommodation {
        id
        title
        uri
        accommodations {
          accommodationsFields {
            imageSlider {
              image {
                altText
                id
                gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
              }
            }
            facility
            bookingButton {
              target
              title
              url
            }
            price
          }
        }
        content
      }
    }
  }
`;
