import React from "react";
import { graphql, Link } from "gatsby";
import Modal from "react-modal";
import ReactPlayer from "react-player";
import parse from "html-react-parser";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Banner = ({ module }) => {
  const [modalIsOpenVideo, setIsOpenVideo] = React.useState(false);

  function openModalVideo() {
    document.body.classList.add("video-modal-open");
    setIsOpenVideo(true);
  }
  function closeModalVideo() {
    document.body.classList.remove("video-modal-open");
    setIsOpenVideo(false);
  }

  const customStylesVideo = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#000000",
      border: "0",
      borderRadius: "0",
      padding: "0",
      width: "100%",
      height: "100%",
    },
  };

  const renderData = () => {
    if (module?.shortVideo) {
      return (
        <div className="video">
          <video 
            width="750" 
            height="500"
            preload="auto"
            playsInline="playsinline" 
            autoPlay 
            muted 
            loop 
            data-src={module?.shortVideo}
            poster={module.fallbackImage?.mediaItemUrl}
          >
            <source src={module?.shortVideo} type="video/mp4" />
          </video >
        </div>
      )
    }
    else if (module?.bannerImage) {
      return (
        <GatsbyImage
          image={getImage(module.bannerImage)}
          alt={module.bannerImage.altText}
          className="img"
        />
      )
    }
    else {
      return null;
    }
  }

  return (
    <>
      <section className={`banner h-screen mdscreen:h-[70vh] relative${module.extraClass ? ` ${module.extraClass}` : ""}`}>
        <div className={`banner-img ${module?.contentPosition === "top" ? "banner-position-top" : ""} ${module?.contentPosition === "center" ? "banner-position-bottom" : ""} ${module?.contentPosition === "bottom" ? "banner-position-bottom" : ""} h-full bg-cover flex justify-center`}>
          {renderData()}
          <div className='banner-info fade-ani px-15 text-center relative '>
            {module.preHeading && <span className='text-white italic font-heading text-16'>{module.preHeading}</span>}
            {module.heading && <h1 className='lg:text-55 lg:leading-73 text-white uppercase'>{module.heading}</h1>}
            {
              module?.description && (
                <div className="text-white italic text-18 font-heading mt-20 lgscreen:mt-10">
                  {parse(module.description)}
                </div>
              )
            }
            {  (module?.homepageBanner === 'Yes') && (
                <div class="mt-35">
                  <Link to={module?.additionalCta?.url} target="_blank" class="button btn-transparent--white">
                    {module?.additionalCta?.title}
                  </Link>
                </div>
              )
            }
            {
              module?.videoUrl && (
                <div className={`${module?.homepageBanner ? "mb-[-150px] pt-[150px]" : "pt-20"} banner-video`}>
                  <button onClick={() => { openModalVideo(); }} className='flex justify-center items-center m-auto'>
                    <div className='video-icon w-20'>
                      <img src="/images/play.png" className='m-auto' alt="Play" />
                    </div>
                    <span className='text-white font-heading uppercase text-13 ml-10'>{module?.buttonCaption}</span>
                  </button>
                </div>
              )
            }
          </div>
        </div>
      </section>
      <Modal
        isOpen={modalIsOpenVideo}
        onRequestClose={closeModalVideo}
        style={customStylesVideo}
      >
        <div className="video-modal-popup h-full">
          <button onClick={closeModalVideo} className="pr-30 pt-30">
            <img
              src="/images/modal-close.png"
              className="m-auto max-w-20"
              alt=""
            />
          </button>
          <div className="modal-video h-full">
            {
              module?.videoUrl && (
                <ReactPlayer
                  className="modal-banner-video"
                  loop={true}
                  playing={true}
                  controls={true}
                  muted={true}
                  url={module?.videoUrl}
                />
              )
            }
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Banner;
export const BannerFragment = graphql`
  fragment BannerFragment on WpPage_Pagecontent_PageContent_Banner {
    id
    hideSection
    homepageBanner
    bannerImage {
      altText
      id
      mediaItemUrl
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1920)
    }
    bannerStyle
    extraClass
    heading
    preHeading
    description
    videoUrl
    shortVideo
    additionalCta {
      target
      title
      url
    }
    fallbackImage {
      altText
      mediaItemUrl
    }
    buttonCaption
    contentPosition
  }
`;
