import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";

const HomeBlogListing = ({ module }) => {

  const pageSize = module?.pagination?.pageSize || "";

  const [numOfItemsToDisplay, setNumOfItemsToDisplay] = useState(pageSize);

  const onLoadMore = () => {
    setNumOfItemsToDisplay(numOfItemsToDisplay + pageSize);
  }

  return (
    <section
      className={`discover-our-stories bg-gray-200 lg:py-100 py-50 ${module.extraClass ? ` ${module.extraClass}` : ""
        }`}
    >
      <div className="container-fluid fade-ani">
        {
          (module?.heading || module?.description) && (
            <div className="section-title fade-ani text-center lg:pb-50 pb-25">
              {module?.heading && (
                <div className="title-black">
                  <h3>Discover our stories from the edge</h3>
                </div>
              )}
              {module?.description && (
                <div className="content">{parse(module?.description)}</div>
              )}
            </div>

          )
        }
        <div className="flex fade-img fade-img flex-wrap lg:mx-minus-15 gap-y-10">
          {module?.blogPost?.length > 0 &&
            module.blogPost.slice(0, numOfItemsToDisplay).map((post) => (
              <div
                className="lg:w-4/12 w-full lg:px-15 px-0 fade-img"
                key={post.id}
              >
                <div className="discover-bx">
                  <GatsbyImage
                    image={getImage(post?.featuredImage?.node)}
                    alt={post?.featuredImage?.node?.altText}
                  />
                  <div className="discover-bx-info lg:pt-20 pt-10 lg:pr-30 pr-0">
                    {post?.title && (
                      <h6 className="text-black-200">{post?.title}</h6>
                    )}
                    {post?.blogpost.shortDescription && (
                      <div className="content text-14">
                        {parse(post?.blogpost.shortDescription)}
                      </div>
                    )}
                    <div className="btn-custom flex flex-wrap items-center space-x-8 mt-20">
                      <Link to={post?.uri || "#"} className="btn-link gold">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {
            module.pagination?.enabled && module?.blogPost?.length > 0 && module?.blogPost?.length > numOfItemsToDisplay && (
              <div className="btn-custom text-center flex justify-center items-center w-full" onClick={onLoadMore}>
                <button className="button btn-transparent">Load more</button>
              </div>
            )
          }
        </div>
      </div>
    </section>
  );
};

export default HomeBlogListing;
export const HomeBlogListingFragment = graphql`
  fragment HomeBlogListingFragment on WpPage_Pagecontent_PageContent_HomeBlogListing {
    id
    hideSection
    heading
    description
    extraClass
    pagination {
      pageSize
      enabled
    }
    blogPost {
      ... on WpPost {
        id
        content
        title
        uri
        blogpost {
          shortDescription
        }
        featuredImage {
          node {
            altText
            id
            gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 500)
          }
        }
      }
    }
  }
`;
