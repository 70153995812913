import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const pageSize = 4;

const GalleryTabs = ({ module }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const [selectedTabName, setSelectedTabName] = useState("all")
  const [imagesToDisplay, setImagesToDisplay] = useState({ "all": pageSize });

  useEffect(() => {
    module?.tabs?.filter((tab) => tab?.heading !== "").forEach((tab) => {
      setImagesToDisplay((prev) => ({
        ...prev,
        [tab.heading]: pageSize
      }))
    });
  }, []);

  const images = [];
  module?.tabs?.filter((tab) => tab?.heading).filter((tab) => selectedTabName === "all" || tab?.heading === selectedTabName).forEach((tab) => {
    tab.image.forEach((image) => {
      images.push(image)
    })
  });  

  const loadMore = () => {
    setImagesToDisplay({
      ...imagesToDisplay,
      [selectedTabName]: imagesToDisplay[selectedTabName] + pageSize
    });
  }

  return (
    <>
      <section className="gallery">
        {
          module?.tabs?.length > 0 && (
            <div className="bg-gray-200 fade-ani py-30">
              <div className="lg:w-7/12 w-full m-auto xlscreen:w-80-per lgscreen:w-full lgscreen:px-30">
                <ul className='tabs w-full flex flex-wrap justify-between mt-0 mdscreen:justify-center'>
                  <li onClick={() => { setSelectedTabName('all') }} className={`tab-link cursor-pointer text-black-200 uppercase text-16 font-heading tracking-0.02 ${(selectedTabName == 'all') ? 'tab-current' : ''}`}>All</li>
                  {
                    module.tabs.filter((tab) => tab?.heading).map((tab, tabIndex) => (
                      <li key={tabIndex.toString()} onClick={() => { setSelectedTabName(tab?.heading) }} className={`tab-link cursor-pointer text-black-200 uppercase text-16 font-heading tracking-0.02 ${selectedTabName === tab?.heading ? 'tab-current' : ''}`}>{tab?.heading || `Tab-${tabIndex}`}</li>
                    ))
                  }
                </ul>
              </div>
            </div>
          )
        }

        <div className="gallery-grid lg:py-75 py-35 fade-img">
          <div className="container-fluid">
            <div className="gallery-tabs-content">
              <div id="overview" className="tab-content fade-img global-list">
                <div className="flex flex-wrap lg:mx-minus-15 mx-0 gap-y-10">
                  {
                    images.slice(0, imagesToDisplay?.[selectedTabName] || pageSize).map((image, imageIndex) => (
                      <div onClick={() => {
                        setPhotoIndex(imageIndex);
                        setisOpen(true);
                      }} className="lg:w-6/12 w-full lg:px-15 px-0 cursor-pointer" key={imageIndex.toString()}>
                        <GatsbyImage
                          image={getImage(image)}
                          alt={image?.altText}
                        />
                      </div>
                    ))
                  }
                  {
                    images?.length > imagesToDisplay?.[selectedTabName] && (
                      <div className="btn-custom text-center flex justify-center items-center w-full lg:mt-50 mt-25">
                        <button className="button btn-transparent" onClick={loadMore}>Load more</button>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].mediaItemUrl}
          nextSrc={images[(photoIndex + 1) % images.length]}
          onImageLoadError={() => { }}
          imageLoadErrorMessage={() => { }}
          prevSrc={
            images[
            (photoIndex + images.length - 1) % images.length
            ]
          }
          onCloseRequest={() => setisOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex) =>
                (photoIndex + images.length - 1) % images.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex(
              (photoIndex) => (photoIndex + 1) % images.length
            )
          }
        />
      )}
    </>
  )
}

export default GalleryTabs;
export const GalleryTabsFragment = graphql`
  fragment GalleryTabsFragment on WpPage_Pagecontent_PageContent_GalleryTabs {
    id
    hideSection
    extraClass
    tabs {
      heading
      image {
        altText
        id
        gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1024)
        mediaItemUrl
      }
    }
  }
`;