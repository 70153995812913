import React from "react";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const AccommodationsGrid = ({ module }) => {
  return (
    <section
      className={`video-grid fade-ani bg-black-300 lg:py-140 py-35 lg:mt-70 mt-35${module.extraClass ? ` ${module.extraClass}` : ""
        }`}
    >
      <div className="container-fluid">
        <div className="section-title fade-ani text-center">
          {module?.heading && (
            <div className="title-white">
              <h2>{module?.heading}</h2>
            </div>
          )}
          {module?.description && (
            <div className="content white">{parse(module.description)}</div>
          )}
          {module?.additionalCta && (
          <div class="mt-35">
          <Link to={module?.additionalCta?.url} target="_blank" class="button btn-transparent--white">
          {module?.additionalCta?.title}
          </Link>
          </div>
          )}
        </div>
        <div className="flex flex-wrap fade-img delay lg:mx-minus-20 mx-0 lg:pt-70 pt-35 lgscreen:space-y-10">
          {module?.accommodationsPost?.length > 0 &&
            module?.accommodationsPost?.map((post) => (
              <div className="lg:w-6/12 w-full lg:px-20 px-0" key={post.id}>
                {post?.accommodations?.accommodationsFields?.videoUrl
                  ? (
                    <div className="video fade-img">
                      <video 
                        width="750" 
                        height="500" 
                        autoPlay 
                        muted 
                        loop
                        poster={post?.accommodations?.accommodationsFields?.fallbackImage?.mediaItemUrl}
                      >
                        <source
                          src={
                            post?.accommodations?.accommodationsFields.videoUrl
                          }
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  ) : (
                    <div className="discover-bx">
                    <GatsbyImage
                    image={getImage(post?.accommodations?.accommodationsFields?.backgroundImage)}
                    alt={post?.accommodations?.accommodationsFields?.fallbackImage?.altText || ""}
                    />
                    </div>
                  )}
                <div className="video-info lg:pt-40 pt-20 lg:pr-50 pr-0">
                  {post?.title && <h4 className="text-white">{post.title}</h4>}
                  {post?.accommodations?.accommodationsFields?.shortDescription && (
                    <div
                      className="content white"
                    >
                      {parse(post.accommodations.accommodationsFields.shortDescription)}
                    </div>
                  )}
                  <div className="btn-custom flex flex-wrap items-center space-x-8 mt-20">
                    <Link to={post?.uri || "#"} className="btn-link white">
                      Explore
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default AccommodationsGrid;
export const AccommodationsGridFragment = graphql`
  fragment AccommodationsGridFragment on WpPage_Pagecontent_PageContent_AccommodationsGrid {
    id
    hideSection
    heading
    description
    additionalCta {
      target
      title
      url
    }
    accommodationsPost {
      ... on WpAccommodation {
        id
        accommodations {
          accommodationsFields {
            backgroundImage {
              altText
              gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000, height: 500)
            }
            videoUrl
            shortDescription
            fallbackImage {
              altText
              mediaItemUrl
            }
          }
        }
        title
        content
        uri
      }
    }
    extraClass
  }
`;
