import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import parse from "html-react-parser";

const SimpleContent = ({ module }) => {
  return (
    <section
      className={`common-content bg-black-200 text-center py-80 lgscreen:py-40${
        module.extraClass ? ` ${module.extraClass}` : ""
      }`}
    >
      <div className="fade-ani">
        <div className="max-w-[695px] m-auto px-20">
          <div className="title-white">
            {module.heading && <h3>{module.heading}</h3>}
          </div>
          {module?.description && (
            <div className="content white py-10">
              {parse(module.description)}
            </div>
          )}
          {module?.link && (
            <div className="btn-custom mt-20">
              <Link
                to={module.link?.url}
                className="btn-link white"
                target={module.link?.target}
              >
                {module?.link.title || "LINK"}
              </Link>
            </div>
          )}
          {module?.secondaryDescription && (
            <div className="content white py-10">
              {parse(module.secondaryDescription)}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default SimpleContent;
export const SimpleContentFragment = graphql`
  fragment SimpleContentFragment on WpPage_Pagecontent_PageContent_SimpleContent {
    id
    description
    extraClass
    heading
    link {
      target
      title
      url
    }
  }
`;
export const AccommodationSimpleContentFragment = graphql`
  fragment AccommodationSimpleContentFragment on WpAccommodation_Accommodations_PageContent_SimpleContent {
    id
    description
    extraClass
    heading
    link {
      target
      title
      url
    }
    secondaryDescription
  }
`;
