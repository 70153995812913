import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser";

const tabsMeta = {
  "terms-and-conditions": "Terms & Conditions",
  "privacy-policy": "Privacy Policy",
  "covid-19": "Covid-19"
}

const NeedToKnowContent = ({ module, location }) => {
  const params = new URLSearchParams(location.search);
  const tabKey = params.get("tab") || "";

  const [selectedTabName, setSelectedTabName] = useState(module.tabs?.[0]?.heading || "")
  
  useEffect(() => {
    if (tabsMeta?.[tabKey]) {
      if (tabKey !== "" && selectedTabName !== tabsMeta[tabKey]) {
        setSelectedTabName(tabsMeta[tabKey]);
      }
    }
  }, [tabKey]);

  const onTabChange = (tabHeading) => {
    console.log(tabHeading)
    setSelectedTabName(tabHeading);
    switch (tabHeading) {
      case "Terms & Conditions": {
        params.set("tab", "terms-and-conditions");
        break;
      }
      case "Privacy Policy": {
        params.set("tab", "privacy-policy");
        break;
      }
      case "Covid-19": {
        params.set("tab", "covid-19");
        break;
      }
      default: {
        return;
      }
    }
    if (typeof window !== undefined) {
      window.history.replaceState({}, '', `${location.pathname}?${params}`);
    }
  }

  const renderContent = (content, contentIndex) => {
    switch(content?.fieldGroupName) {
      case "page_Pagecontent_PageContent_NeedToKnowContent_tabs_Content_Heading": {
        return (
          <h2 className={content?.extraClasses ? `${content.extraClasses}` : ""} key={contentIndex.toString()}>{content?.text}</h2>
        )
      }
      case "page_Pagecontent_PageContent_NeedToKnowContent_tabs_Content_Paragraph": {
        return (
          <div className={content?.extraClasses ? `${content.extraClasses}` : ""} key={contentIndex.toString()}>{parse(content?.text)}</div>
        )
      }
      case "page_Pagecontent_PageContent_NeedToKnowContent_tabs_Content_SubHeading": {
        return (
          <h6 className={content?.extraClasses ? `${content.extraClasses}` : ""} key={contentIndex.toString()}>{content?.text}</h6>
        )
      }
      default:
        return null;
    }
  }

  return (
    <section className="gallery">
      <div className="bg-gray-200 py-30">
        <div className="lg:w-7/12 w-full m-auto xlscreen:w-80-per lgscreen:w-full lgscreen:px-30">
          <ul className='tabs w-full fade-ani flex flex-wrap justify-center gap-x-10 mt-0 mdscreen:justify-center'>
            {/* <Link to={`/need-to-know?tab=terms-and-conditions`}>
              <li className={`tab-link cursor-pointer text-black-200 uppercase text-16 font-heading tracking-0.02 ${(selectedTabName == "Terms & Conditions") ? 'tab-current' : ''}`}>Terms & Conditions</li>
            </Link>
            <Link to={`/need-to-know?tab=privacy-policy`}>
              <li className={`tab-link cursor-pointer text-black-200 uppercase text-16 font-heading tracking-0.02 ${(selectedTabName == "Privacy Policy") ? 'tab-current' : ''}`}>Privacy Policy</li>
            </Link>
            <Link to={`/need-to-know?tab=covid-19`}>
              <li className={`tab-link cursor-pointer text-black-200 uppercase text-16 font-heading tracking-0.02 ${(selectedTabName == "Covid-19") ? 'tab-current' : ''}`}>Covid-19</li>
            </Link> */}
            {module?.tabs?.length > 0 &&
              module.tabs.map((tab) => (
                <li key={tab.heading} onClick={() => onTabChange(tab?.heading)} className={`tab-link cursor-pointer text-black-200 uppercase text-16 font-heading tracking-0.02 ${(selectedTabName == tab.heading) ? 'tab-current' : ''}`}>{tab.heading}</li>
              ))}
          </ul>
        </div>
      </div>
      <div className="gallery-grid fade-ani lg:py-75 py-35">
        <div className="container m-auto">
          <div className="need-to-know-content">
            {module?.tabs?.length > 0 &&
              module.tabs.map((tab, tabIndex) => (
                selectedTabName == tab.heading && <div id="overview" className="tab-content global-list" key={tabIndex.toString()}>
                  <div className="tabs-content px-20">
                    <div className="content global-list">
                      {tab.content.map(renderContent)}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default NeedToKnowContent;
export const NeedToKnowFragment = graphql`
  fragment NeedToKnowFragment on WpPage_Pagecontent_PageContent_NeedToKnowContent {
    id
    hideSection
    extraClass
    tabs {
      content {
        ... on WpPage_Pagecontent_PageContent_NeedToKnowContent_tabs_Content_Heading {
          extraClasses
          text
          fieldGroupName
        }
        ... on WpPage_Pagecontent_PageContent_NeedToKnowContent_tabs_Content_SubHeading {
          extraClasses
          text
          fieldGroupName
        }
        ... on WpPage_Pagecontent_PageContent_NeedToKnowContent_tabs_Content_Paragraph {
          extraClasses
          text
          fieldGroupName
        }
      }
      heading
    }
  }
`;