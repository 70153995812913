import React, { useRef, scrollRef } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectFade, Navigation } from "swiper";
import parse from "html-react-parser";

const SliderWithContent = ({ module }) => {
  return (
    <section
      className={`zigzag ${
        !module?.backgroundColor || module?.backgroundColor === "bg_white"
          ? "bg-white"
          : "bg-gray-200"
      } lg:py-60 py-30 lg:mt-60 mt-30${
        module.extraClass ? ` ${module.extraClass}` : ""
      }${module.imageStyle !== "portrait" ? ` ${module.imageStyle}` : ""}`}
    >
      <div className="container-fluid">
        <div className="flex flex-wrap items-center">
          <div
            className={`lg:w-6/12 fade-img w-full${
              module?.sliderPosition === "right"
                ? " pl-30 lgscreen:pl-0"
                : " pr-30 lgscreen:pr-0"
            } ${module?.sliderPosition === "right" ? " lg:order-2" : ""}`}
          >
            <div className="zigzag-slider fade-img overflow-hidden">
              <Swiper
                spaceBetween={0}
                loop={false}
                speed={1000}
                slidesPerView={1}
                effect={"fade"}
                navigation={true}
                modules={[EffectFade, Navigation]}
                className="overflow-hidden"
                data-scroll
                data-scroll-class="xyz-in"
                xyz="big-25% duration-30"
              >
                {module?.imageSlider?.length > 0 &&
                  module.imageSlider.map((slide, slideIndex) => (
                    <SwiperSlide key={slideIndex.toString()}>
                      <GatsbyImage
                        image={getImage(slide?.image)}
                        alt={slide?.image?.altText}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>

          <div
            className={`lg:w-6/12 w-full fade-ani fade-ani ${
              module?.sliderPosition === "right" ? " lg:order-1" : ""
            }`}
          >
            <div className="zigzag-content max-w-[443px] m-auto">
              {module?.heading && (
                <div className="title-black">
                  <h3>{module?.heading}</h3>
                </div>
              )}
              {module?.description && (
                <div className="content">{parse(module.description)}</div>
              )}
              {module?.link && (
                <div className="btn-custom flex flex-wrap items-center space-x-8 mt-30">
                  <Link
                    to={module?.link?.url}
                    className="btn-link gold"
                    target={module?.link?.target}
                  >
                    {module?.link?.title}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SliderWithContent;
export const SliderWithContentFragment = graphql`
  fragment SliderWithContentFragment on WpPage_Pagecontent_PageContent_SliderWithContent {
    id
    hideSection
    description
    extraClass
    backgroundColor
    heading
    imageBox {
      description
      position
      title
    }
    imageSlider {
      image {
        altText
        id
        gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1400)
      }
    }
    imageStyle
    link {
      target
      title
      url
    }
    sectionStyle
    sliderPosition
  }
`;
