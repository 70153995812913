import React, { useEffect, useRef, scrollRef } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Main from "../modules/Main";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Page = (props) => {
  const post = props.data.post;
  const { location } = props;

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.utils.toArray(".fade-ani").forEach(function (box) {
      ScrollTrigger.create({
        trigger: box,
        toggleActions: "play none none none",
        once: true,
        onEnter: function () {
          gsap.fromTo(
            box,
            { autoAlpha: 0, y: 40 },
            { duration: 0.8, delay: 0.4, autoAlpha: 1, y: 0 }
          );
        },
      });
    });

    gsap.utils.toArray(".fade-img").forEach(function (box) {
      ScrollTrigger.create({
        trigger: box,
        toggleActions: "play none none none",
        once: true,
        onEnter: function () {
          gsap.fromTo(
            box,
            { autoAlpha: 0, y: 40 },
            { duration: 0.8, delay: 0.3, autoAlpha: 1, y: 0 }
          );
        },
      });
    });
  }, []);

  useEffect(() => {
    document.body.classList.remove("nav-menu-open", "video-modal-open");
  }, []);

  return (
    <div className="fullPageanimation">
      <Layout>
        <Seo seo={post.seo} />
        <Main modules={post.pageContent} location={location} />
      </Layout>
    </div>
  );
};

export default Page;
export const pageQuery = graphql/* GraphQL */ `
  query PageById($id: String!) {
    post: wpPage(id: { eq: $id }) {
      id
      title
      ...seoPageFragment
      ...MainFragment
    }
  }
`;
